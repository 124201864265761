import React, { useContext, useMemo, useState } from "react";

export const WaitingForImageContext = React.createContext(null);

export const useWaitingForImageContext = () => {
    const [waitingForImage, setWaitingForImage] = useState(false);

    const context = useMemo(() => ({
        waitingForImage,
        setWaitingForImage
    }), [waitingForImage, setWaitingForImage]);

    return context
}

export const useWaitingForImage = () => {
    return useContext(WaitingForImageContext)
}