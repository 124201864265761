import {
    BanknotesIcon,
    CheckBadgeIcon,
    ClockIcon,
    UsersIcon,
  } from '@heroicons/react/24/outline'
import { NavLink, useParams } from 'react-router-dom'
import { useApiClient } from '../../../state/apiClient';
import { useUserInfo } from '../../../state/userInfo';

  const actions = [
    {
      title: 'Acquire Camera Images',
      href: 'acquire',
      icon: ClockIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
    },
    {
      title: 'Upload Images',
      href: 'upload',
      icon: CheckBadgeIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },
    {
      title: 'Additional Exam Details',
      href: 'other',
      icon: UsersIcon,
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
    },
    {
      title: 'Review and Finalize Exam',
      href: 'review',
      icon: BanknotesIcon,
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-50',
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  export default function ActiveSession () {
    const { sessionId } = useParams();
    const { getActiveSessionId, clearActiveSessionId} = useUserInfo();
    const activeSessionId = getActiveSessionId();
    const { apiClient } = useApiClient()

    const endExamSession = () => {
      clearActiveSessionId({ apiClient })
    }

    return (
      <>
                      Looks like you have an active exam session in progress. 
                Click the actions below to continue the exam session or END this exam session.
        SESSIONID: {sessionId}
        ACTIVESESSIONID: {activeSessionId}
        <button onClick={endExamSession}>End Exam Session</button>
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">

        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <NavLink
                  to={sessionId ? action.href : `${activeSessionId}/${action.href}`}
                  end
                  className="focus:outline-none"
                >
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </NavLink>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et
                quo et molestiae.
              </p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
      </>
    )
  }