import { useState } from "react"
import { ImageViewer } from "./ImageViewer/ImageViewer"
import { ThumbnailStrip } from "./ThumbnailStrip"

export const ImageNavigator = ({
    thumbnails,
    updateImageInfo,
    currentImage,
    onImageChange,
    onExit
}) => {
    const [performingAction, setPerformingAction] = useState(false)
    const [loadingImage, setLoadingImage] = useState()

    const onChangeImage = async (imageId) => {
        setLoadingImage(true)
        await(onImageChange(imageId))
        setLoadingImage(false)
    }

    return (
        <div className="h-full flex max-w-7xl">
            <div className="h-full basis-64 flex-none flex flex-col px-4 pt-8 sm:px-6 lg:px-8 gap-y-6 bg-white border-r border-gray-200 bg-gray-50">
                <button onClick={onExit}>Back</button>
                { thumbnails?.length > 0 && (
                    <ThumbnailStrip
                        thumbnails={thumbnails}
                        onThumbnailClick={onChangeImage}
                        performingOutsideAction={performingAction}
                        selectedImageId={currentImage?._id}
                    />
                )}
            </div>
            <div className="flex-grow overflow-y-auto px-4 pt-8 sm:px-6 lg:px-8">
                <ImageViewer
                    image={currentImage}
                    loadingImage={loadingImage}
                    setPerformingAction={setPerformingAction}
                    updateImageInfo={updateImageInfo}
                />
            </div>
        </div>
    )
}