export const AlertNotificationsHolder = () => {
    return (
        <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div id="root_notifications_holder" aria-live="assertive" className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          </div>
        </div>
        </>
    )
}