import React, { useEffect, useState } from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import { AlertNotificationsHolder } from "./layout/components/AlertNotificationsHolder";
import Navbar from './layout/navigation/Navbar'
import { ImageAcquisitionPage } from './pages/image-acquisition/ImageAcquisitionPage'
import { NavigationContext, useNavigationContext } from './layout/navigation/useNavigationContext';
import { NavbarMobile } from "./layout/navigation/NavbarMobile";
import { HomePage } from "./pages/home/HomePage";
import { ContentArea } from "./layout/navigation/ContentArea";

import { useAuthenticator } from '@aws-amplify/ui-react'
import { useApiClient } from "./state/apiClient";
import ApiClient from "./utility/ApiClient";
import { ImageUploadPage } from "./pages/image-upload/ImageUploadPage";
import { ImageGalleryPage } from "./pages/image-gallery/ImageGalleryPage";
import { SessionPage } from "./pages/session/SessionPage";
import { useUserInfo } from "./state/userInfo";
import { useImageInfo } from "./state/imageInfo";
import { useExamSessionInfo } from "./state/examSessionInfo";


export const AuthenticatedLayout = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const { apiClient, setApiClient } = useApiClient()
  const { userInfo, getOrFetchUserInfo, getActiveSessionId } = useUserInfo()
  const { setImageList } = useImageInfo()
  const { setExamSessionInfo } = useExamSessionInfo()

  useEffect(() => {
    console.log({ user })
    if (user?.signInUserSession.idToken.jwtToken) {
      console.log("HAVE A TOKEN")
      setApiClient(new ApiClient(user))
    }
  }, [user])


  useEffect(() => {
    if (apiClient) {
      console.log("FETCHING USER INFO")
      getOrFetchUserInfo({ apiClient })
    }

  }, [apiClient])

  useEffect(() => {
    const sessionId = getActiveSessionId()
    if (sessionId && apiClient) {
      apiClient.getImageList({ sessionId }).then((images) => setImageList(images))
      apiClient.getSessionInfo({ sessionId }).then((sessionInfo) => setExamSessionInfo(sessionInfo))
    }
  }, [userInfo])

  return (
    <>
    <div id="iat-app" className="min-h-full">


    { userInfo?._id &&
        <NavigationContext.Provider value={useNavigationContext}>
            <Navbar />
            { false &&
            <NavbarMobile mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
            }
            { false &&
            <header className="bg-white shadow-sm">
              <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
                <h1 className="text-lg font-semibold leading-6 text-gray-900">Dashboard</h1>
              </div>
            </header>
            }
            <main>
              <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-4 sm:px-0">
                  <Routes>
                  <Route path="/" exact={true} element={<HomePage />} />
                  <Route path="/session/*" element={<SessionPage />} />
                  <Route path="/acquire-images" element={<ImageAcquisitionPage />} />
                  <Route path="/upload-images" element={<ImageUploadPage />} />
                  <Route path="/images/all" element={<ImageGalleryPage />} />
                </Routes>
                </div>
              </div>
            </main>
            { false &&
            <ContentArea setMobileMenuOpen={setMobileMenuOpen}>
            </ContentArea>
            }
            <AlertNotificationsHolder />
        </NavigationContext.Provider>
    }
    </div>
    </>
  )
}

