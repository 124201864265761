import create from 'zustand'
import _ from 'lodash'

export const useUserInfo = create((set, get) => ({
    userInfo: {},
    getOrFetchUserInfo: async ({ apiClient }) => {
        let info = get().userInfo;
        if (_.isEmpty(info)) {
            info = await apiClient.getUser()
            if (!_.isEmpty(info)) {
                set((state) => ({
                    ...state,
                    userInfo: info
                }))
            }
        }
        return info;
    },
    getActiveSessionId: () => {
        return get().userInfo?.preferences?.activeSessionId
    },
    setActiveSessionId: async ({ sessionId, apiClient }) => {
        await apiClient.setUserPreferences({
            activeSessionId: sessionId
        });
        set((state) => ({
            ...state,
            userInfo: {
                ...state.userInfo,
                preferences: {
                    ...state.userInfo.preferences,
                    activeSessionId: sessionId
                }
            }
        }))
    },
    clearActiveSessionId: async({ apiClient }) => {
        await apiClient.setUserPreferences({
            activeSessionId: null
        });
        set((state) => ({
            ...state,
            userInfo: {
                ...state.userInfo,
                preferences: {
                    ...state.userInfo.preferences,
                    activeSessionId: null
                }
            }
        }))
    }

}))