import { useState } from "react"
import { Route, Routes } from "react-router-dom";
import { SmallIconButton } from "../../layout/components/Button";
import { useApiClient } from "../../state/apiClient";
import { useExamSessionInfo } from "../../state/examSessionInfo";
import { useUserInfo } from "../../state/userInfo";
import { Acquire } from "./components/Acquire";
import ActiveSession from "./components/ActiveSession";
import { Navbar } from "./components/Navbar";
import { Review } from "./components/Review";
import { SessionForm } from "./components/SessionForm";
import { Upload } from "./components/Upload";

export const SessionPage = () => {
    const [showSessionForm, setShowSessionForm] = useState()
    const { apiClient } = useApiClient()
    const { getActiveSessionId, setActiveSessionId } = useUserInfo()
    const { setExamSessionInfo } = useExamSessionInfo()

    const activeSessionId = getActiveSessionId();

    const onCancel = () => {
        setShowSessionForm(false)
    }

    const onSessionCreated = async (session) => {
        await setActiveSessionId({ sessionId: session?._id, apiClient })
        await setExamSessionInfo(session)
        setShowSessionForm(false)
    }
    return (
        <>
            { !showSessionForm && !activeSessionId &&
                <SmallIconButton
                    onClick={() => setShowSessionForm(true)}
                >
                    Start a Sperm Morphology Session
                </SmallIconButton>
            }
            { !activeSessionId && showSessionForm &&
                <SessionForm onCancel={onCancel} onSessionCreated={onSessionCreated} />
            }
            { activeSessionId &&
                <div>
                { false &&
                <Navbar />
                }
                <Routes>
                    <Route path="" element={<ActiveSession />} />
                    <Route path=":sessionId" element={<ActiveSession />} />
                    <Route path=":sessionId/acquire" element={<Acquire />} />
                    <Route path=":sessionId/upload" element={<Upload />} />
                    <Route path=":sessionId/review" element={<Review />} />
                </Routes>
                </div>
            }

        </>
    )
}