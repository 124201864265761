import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";

import { Amplify } from 'aws-amplify'

import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';

import { AuthenticatedLayout } from "./AuthenticatedLayout";

Amplify.configure({
  Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      mandatorySignIn: true,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  }
})

const components = {
  Header() {
      return (
              <div className="text-center">
                  <h1 className="mb-4 mt-24 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
                      Integris Vet Verified
                  </h1>
              </div>
      )
  }
}


function App() {
  const LANE = process.env.REACT_APP_LANE;
  const showEnvContextBar = ['devtest','staging','localhost'].includes(LANE)
  const contextBarColorMap = {
    devtest: 'bg-yellow-600',
    localhost: 'bg-green-700',
    staging: 'bg-red-700'
  }
  const contextBarColor = contextBarColorMap[LANE] || 'blue';

  return (
    <Router>
      <Authenticator.Provider>
        <Authenticator components={components} hideSignUp={true}>
          { showEnvContextBar &&
            <header className={`w-full`}>
                <div className={`w-full flex justify-center text-center text-sm relative z-10 flex h-8 flex-shrink-0 p-2 ${contextBarColor} shadow-sm text-white`}>
                    <span className="text-center">
                    <span className="font-bold">{LANE.toUpperCase()}</span>
                    - you are currently using the {LANE} environment.
                    </span>
                </div>
            </header>
          }
          <AuthenticatedLayout />
        </Authenticator>
      </Authenticator.Provider>
    </Router>
  )
}

export default App;
