export const ThumbnailStrip = ({
    thumbnails,
    onThumbnailClick,
    performingOutsideAction,
    selectedImageId
}) => {
    return (
        <div className="w-full flex flex-col overflow-y-auto">
            { thumbnails?.length > 0 && (
                <div className="grid grid-rows-10 grid-cols-2 gap-1">
                    {thumbnails.map((thumb, i) =>
                        <div key={i} className={`border-8 ${(selectedImageId === thumb.imageId) ? "border-sky-300" : "border-white"}`}>
                            <button onClick={() => onThumbnailClick(thumb.imageId)} className="w-full h-full object-cover" disabled={performingOutsideAction}>
                                <img src={thumb.src} className={`${performingOutsideAction && "opacity-50"} h-full object-cover`} />
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}