import { EyeIcon } from "@heroicons/react/20/solid";
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
import { useEffect, useState } from "react";
import { SmallIconButton } from "../../../layout/components/Button";
import ListBox from "../../../layout/components/ListBox";
import { LoadingSpinner } from "../../../layout/components/LoadingSpinner";
import { useApiClient } from "../../../state/apiClient";
import { useRoboflowInfo } from "../../../state/roboflowInfo";
import _ from 'lodash';

export const Annotations = ({ image, imageRef, nodeSrc, imageReady, updateImageInfo}) => {
    const [anno, setAnno] = useState()
    const [roboflowProjects, setRoboflowProjects] = useState()
    const [selectedProject, setSelectedProject] = useState()
    const [selectedProjectId, setSelectedProjectId] = useState()
    const [selectedModelId, setSelectedModelId] = useState()
    const [modelsLoading, setModelsLoading] = useState()
    const [annotationModelId, setAnnotationModelId] = useState()
    const [displayedImageWidth, setDisplayedImageWidth] = useState()
    const [runningModel, setRunningModel] = useState()

    const { apiClient } = useApiClient();
    const { getOrFetchProjects, getOrFetchProjectDetails } = useRoboflowInfo()
    useEffect(() => {
        const fetchProjects = async () => {
            const projects = await getOrFetchProjects({ apiClient })
            setRoboflowProjects(projects)
        }
        fetchProjects()
    }, [])

    useEffect(() => {
        if (imageReady) {
            let anno = null;
            if (imageRef.current) {
                console.log('CREATING ANNO')
                anno = new Annotorious({
                    image: imageRef.current,
                    // readOnly: true,
                });
                anno.on('createAnnotation', (annotation) => console.log(annotation))
            }
            setAnno(anno)
            return () => {
                console.log('destroying annotorious')
                anno.destroy()
            }
        }
    }, [imageReady])

    useEffect(() => {
        console.log('clearing!')
        anno?.clearAnnotations()
        setDisplayedImageWidth(imageRef.current?.naturalWidth)
        setSelectedProjectId(null)
        setSelectedModelId(null)
        setAnnotationModelId(null)
        setSelectedProject(null)
    }, [nodeSrc]);

    const predictions = image?.roboflow_predictions || {}
    const predictionModels = Object.keys(predictions)

    const getBoundingBoxes = (prediction) => {
        console.log({ prediction })
        const ratio = displayedImageWidth / prediction.image.width;
        const boxes = prediction.predictions;
        return boxes.map((box, idx) => {
            const width = box.width * ratio;
            const height = box.height * ratio;
            const x = box.x * ratio;
            const y = box.y * ratio;
            const xoff = width / 2;
            const yoff = height / 2
            const p1 = `${parseInt(x - xoff)},${parseInt(y - yoff)}`;
            const p2 = `${parseInt(x + xoff)},${parseInt(y - yoff)}`;
            const p3 = `${parseInt(x - xoff)},${parseInt(y + yoff)}`;
            const p4 = `${parseInt(x + xoff)},${parseInt(y + yoff)}`;
            return {
                '@context': 'http://www.w3.org/ns/anno.jsonld',
                id: `${image._id}--${idx}`,
                type: 'Annotation',
                body: [{
                    type: 'TextualBody',
                    purpose: 'tagging',
                    value: box.class
                }],
                target: {
                    selector: [{
                        type: 'SvgSelector',
                        value: `<svg><polygon style='stroke: red' points='${p1} ${p2} ${p4} ${p3}'></polygon></svg>`
                    }]
                }
            }
        })
    }

    const loadAnnotation = (model) => {
        anno.clearAnnotations()
        const prediction = predictions[model]
        const boundingBoxes = getBoundingBoxes(prediction)

        boundingBoxes.forEach(box => {
            console.log('adding')
            anno.addAnnotation(box, true)
        })
    }

    useEffect(() => {
        console.log('CHANGE!')
        console.log({ annotationModelId, preds: image?.roboflow_predictions})
        if (annotationModelId && !_.isEmpty(image?.roboflow_predictions?.[annotationModelId])) {
            loadAnnotation(annotationModelId)
        } else {
            anno?.clearAnnotations()
        }
    }, [annotationModelId, image?.roboflow_predictions])

    const onProjectSelect = async (projectId) => {
        setSelectedProjectId(projectId)
        setSelectedModelId(null)
        setModelsLoading(true)
        const projectDetails = await getOrFetchProjectDetails({ apiClient, roboflowProject: projectId })
        setModelsLoading(false)
        setSelectedProject(projectDetails)
    }

    const onModelSelect = async (modelId) => {
        setSelectedModelId(modelId)
    }

    const projectDropdownItems = roboflowProjects?.length && roboflowProjects.map((p) => ({
        name: p.name,
        id: p.id
    }))

    const modelDropdownItems = selectedProject?.versions?.length && selectedProject.versions.map((versionInfo) => {
        const alreadyRun = Object.keys(image?.roboflow_predictions || {})
        const model = versionInfo?.model || {}
        const display = alreadyRun.includes(model.id) ?
            <span>{model.id} <span className="text-gray-400 italic">[already run]</span></span>
            :
            model.id
        return {
            name: display,
            id: model.id
        }
    })

    const runModel = async (modelId) => {
        // TOOD: show loading
        setRunningModel(true)
        const rsp = await apiClient.runRoboflowInference({ modelId, imageId: image._id })
        setRunningModel(false)
        console.log({ rsp })
        updateImageInfo({
            ...image,
            roboflow_predictions: {
                ...image.roboflow_predictions,
                [modelId]: rsp,
            }
        })
        setAnnotationModelId(modelId)
    }

    const onLoadAnnotationClick = (modelId) => {
        if (annotationModelId === modelId) {
            setAnnotationModelId(null)
        } else {
            setAnnotationModelId(modelId)
        }
    }

    return (
        <>
        { imageReady && (
        <>
            <ul role="list" className="divide-y divide-gray-200">
                {predictionModels.map((model, i) => (
                    <li key={i} className={annotationModelId === model ? `flex p-4 bg-gray-100` : 'flex p-4'}>
                        <button className="flex" onClick={() => onLoadAnnotationClick(model)}>
                            <EyeIcon className={annotationModelId === model ? `h-6 w-6 text-green-300` : 'h-6 w-6 text-gray-300'} />
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-900">
                                    {model}
                                </p>
                            </div>
                        </button>
                    </li>
                ))}
            </ul>
            { projectDropdownItems && (
                <div>
                <ListBox
                    items={projectDropdownItems}
                    onChange={onProjectSelect}
                    selectedId={selectedProjectId}
                    label={"Auto-annotate with Roboflow:"}
                />
                </div>
            )}
            { modelsLoading &&
                <LoadingSpinner />
            }
            { selectedProjectId && modelDropdownItems?.length > 0 && !modelsLoading && (
                <div>
                <ListBox
                    items={modelDropdownItems}
                    onChange={onModelSelect}
                    selectedId={selectedModelId}
                />
                </div>
            )}
            { selectedModelId && (
                <SmallIconButton onClick={() => runModel(selectedModelId)} disabled={runningModel}>Run Roboflow Model</SmallIconButton>
            )}
            { runningModel && (
                <>
                <LoadingSpinner /> <span>Running model...</span>
                </>
            )}
        </>
        )}
        </>
    )
}