import { THUMBNAIL_URL } from "../../../utility/environment";
import CollapsibleContent from "../../../layout/components/CollapsibleContent";
import { RoboflowUploader } from "./RoboflowUploader";
import moment from "moment";
import { useCallback, useState, useRef } from "react";
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
import { SmallIconButton } from "../../../layout/components/Button";
import { PencilIcon } from "@heroicons/react/24/outline";
import { ClipboardDocumentListIcon, InformationCircleIcon, RectangleGroupIcon, Square3Stack3DIcon } from "@heroicons/react/20/solid";
import { Info } from "./Info";
import { Annotations } from "./Annotations";

const BASE_THUMB_URL = THUMBNAIL_URL
const THUMB_FOLDER_SIZE = '600';

export const ImageViewer = ({
    image,
    loadingImage,
    setPerformingAction,
    updateImageInfo
}) => {
    const [inAnnotationMode, setInAnnotationMode] = useState()
    const [annoInstance, setAnnoInstance] = useState();
    const [currentTab, setCurrentTab] = useState('Annotation')

    const imageRef = useRef();
    const [nodeSrc, setNodeSrc] = useState()
    const [imageReady, setImageReady] = useState()
    const smartImageRef = useCallback((node) => {
        if (imageRef.current) {
            console.log("OLD NODE GOES AWAY HERE")
        }
        imageRef.current = node
        setNodeSrc(imageRef.current?.src)

        if (imageRef.current) {
            console.log("HAVE A NEW NODE")
            setImageReady(true)
        }
    })

    const toggleAnnotationMode = () => {
        if (inAnnotationMode) {
            annoInstance && annoInstance.destroy()
            setAnnoInstance(null)
            setInAnnotationMode(false)
        }
        else {
            if (!annoInstance) {
                const anno =  new Annotorious({
                    image: imageRef.current
                })
                setAnnoInstance(anno)
            }
            setInAnnotationMode(true)
        }
    }

    const getImageLink = (image) => {
        const fileName = image?.file_name;
        return fileName ? `https://${BASE_THUMB_URL}/${THUMB_FOLDER_SIZE}/${fileName}` : '';
    }

    const tabs = [
        { name: 'Annotation', href: '#', icon: RectangleGroupIcon, current: currentTab === 'Annotation' },
        { name: 'Datasets', href: '#', icon: Square3Stack3DIcon, current: currentTab === 'Datasets' },
        { name: 'Reports', href: '#', icon: ClipboardDocumentListIcon, current: currentTab === 'Reports' },
        { name: 'Info', href: '#', icon: InformationCircleIcon, current: currentTab === 'Info' },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const TabHeader = ({children}) => {
        return (
            <h2 className="text-xl font-bold mb-4 text-primarycolor-500">
                {children}
            </h2>
        )
    }

    return (
        <div className="h-full">
            { image?._id &&
                <div className="flex gap-4 justify-start">
                    <div className="flex-none">
                        <img src={getImageLink(image)} ref={smartImageRef} />
                    </div>
                    <div className="flex-1">
                        <div className="w-full flex pl-6 flex-col gap-y-4 justify-between">
                            <div className="">
                                <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                    <button
                                        key={tab.name}
                                        href={tab.href}
                                        className={classNames(
                                        tab.current
                                            ? 'border-primarycolor-500 text-primarycolor-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium'
                                        )}
                                        aria-current={tab.current ? 'page' : undefined}
                                    >
                                        <tab.icon
                                        className={classNames(
                                            tab.current ? 'text-primarycolor-500' : 'text-gray-400 group-hover:text-gray-500',
                                            '-ml-0.5 mr-2 h-8 w-8'
                                        )}
                                        aria-hidden="true"
                                        onClick={() => setCurrentTab(tab.name)}
                                        />
                                        <span></span>
                                    </button>
                                    ))}
                                </nav>
                                </div>
                            </div>

                            { currentTab === 'Annotation' &&
                                <div className="h-full">
                                    <TabHeader>Annotation</TabHeader>
                                    <Annotations
                                        image={image}
                                        imageRef={imageRef}
                                        nodeSrc={nodeSrc}
                                        imageReady={imageReady}
                                        updateImageInfo={updateImageInfo}
                                    />
                                </div>
                            }

                            { currentTab === 'Datasets' &&
                                <div className="h-full">
                                    <TabHeader>Roboflow Datasets</TabHeader>
                                    <RoboflowUploader
                                        activeImage={image}
                                        setPerformingAction={setPerformingAction}
                                        updateImageInfo={updateImageInfo} />
                                </div>
                            }

                            { currentTab === 'Reports' &&
                                <div className="h-full">
                                    <TabHeader>Reports</TabHeader>
                                </div>
                            }

                            { currentTab === 'Info' &&
                                <div className="h-full">
                                    <TabHeader>Info</TabHeader>
                                    <Info image={image} />
                                </div>
                            }

                            { false &&
                            <>
                                <div>
                                <CollapsibleContent
                                    title="Image Info"
                                    className="bg-primarycolor-500"
                                >
                                    <div className="grid grid-cols-1">
                                        <h3 className="text-sm font-medium leading-6 text-gray-900">Acquired Date</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">{moment(image.date_completed).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    </div>

                                </CollapsibleContent>
                                </div>
                                <CollapsibleContent
                                    title="Annotations"
                                >
                                    <SmallIconButton onClick={toggleAnnotationMode} icon={PencilIcon}>
                                        { inAnnotationMode
                                            ? `Stop Annotating`
                                            : `Annotate`
                                        }
                                    </SmallIconButton>
                                </CollapsibleContent>
                                <div>
                                <CollapsibleContent
                                    title="Datasets"
                                >
                                    <RoboflowUploader
                                        activeImage={image}
                                        setPerformingAction={setPerformingAction}
                                    />
                                </CollapsibleContent>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            }
            { loadingImage &&
                <div role="status" className="flex-grow flex justify-center items-center max-w-sm h-56 bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700">
                    <svg className="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"></path></svg>
                    <span className="sr-only">Loading...</span>
                </div>
            }
        </div>
    )
}