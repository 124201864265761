import { useState } from "react"
import { useApiClient } from "../../../state/apiClient"

export const SessionForm = ({ onCancel, onSessionCreated }) => {
    const [bullId, setBullId] = useState()
    const [name, setName] = useState()
    const [clientName, setClientName] = useState()

    const { apiClient } = useApiClient();

    const save = async (e) => {
        e.preventDefault()

        const session = {
            'bull-id': bullId,
            'client-name': clientName,
            name
        }

        const sessionInfo = await apiClient.createSession(session)
        onSessionCreated(sessionInfo)
    }

    return (
      <form className="space-y-8 divide-y divide-gray-200" onSubmit={save}>
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Session Info</h3>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Session Name
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="client-name" className="block text-sm font-medium text-gray-700">
                  Client Name
                </label>
                <div className="mt-1">
                  <input
                    id="client-name"
                    name="client-name"
                    type="text"
                    autoComplete=""
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="bull-id" className="block text-sm font-medium text-gray-700">
                  Bull ID
                </label>
                <div className="mt-1">
                  <input
                    id="bull-id"
                    name="bull-id"
                    type="text"
                    autoComplete=""
                    value={bullId}
                    onChange={(e) => setBullId(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Analysis Settings</h3>
              <p className="mt-1 text-sm text-gray-500">
                What models to automatically run when images are acquired
              </p>
            </div>
            <div className="mt-6">
              <fieldset>
                <legend className="sr-only">Roboflow Models</legend>
                <div className="text-base font-medium text-gray-900" aria-hidden="true">
                  Roboflow Models
                </div>
                <div className="mt-4 space-y-4">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="comments" className="font-medium text-gray-700">
                        Model Name Here (1)
                      </label>
                      <p className="text-gray-500">Description of Roboflow Model Here</p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="comments" className="font-medium text-gray-700">
                        Model Name Here (1)
                      </label>
                      <p className="text-gray-500">Description of Roboflow Model Here</p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="comments" className="font-medium text-gray-700">
                        Model Name Here (1)
                      </label>
                      <p className="text-gray-500">Description of Roboflow Model Here</p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="comments" className="font-medium text-gray-700">
                        Model Name Here (1)
                      </label>
                      <p className="text-gray-500">Description of Roboflow Model Here</p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onCancel}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primarycolor-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    )
}