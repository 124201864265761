import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useExamSessionInfo } from '../../state/examSessionInfo'
import { useUserInfo } from '../../state/userInfo'
import { NavLink, useLocation } from "react-router-dom"
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useApiClient } from '../../state/apiClient'

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
  { name: 'Reports', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const { signOut, user } = useAuthenticator((context) => [context.user]);
    const { examSessionInfo } = useExamSessionInfo();
    const { getActiveSessionId, clearActiveSessionId} = useUserInfo();
    const { apiClient } = useApiClient();

    const activeSessionId = getActiveSessionId();
    const location = useLocation();
    const sessionLinks = [
        {
            to: `/session/${activeSessionId}/acquire`,
            displayName: 'Camera'
        },
        {
            to: `/session/${activeSessionId}/upload`,
            displayName: 'Upload'
        },
        {
            to: `/session/${activeSessionId}/review`,
            displayName: 'Review'
        },
    ]

    const noSessionLinks = [
        {
            to: '/session',
            displayName: 'New Exam Session'
        },
        {
            to: '/sessions',
            displayName: 'All Exams'
        }
    ]
    const useLinks = activeSessionId ? sessionLinks : noSessionLinks;
    const listItems = useLinks.map((linkInfo) => {
        return {
          ...linkInfo,
          active: location.pathname === linkInfo.to ? true : false
        }
      })
    const avatarInitials = 'CB'
    return (
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex">
                        <div className="-ml-2 mr-2 flex items-center md:hidden">
                            {/* Mobile menu button */}
                            <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                )}
                            </Disclosure.Button>
                        </div>
                        <div className="flex flex-shrink-0 items-center">
                            <NavLink to="/">
                            <img
                                className="h-8 w-auto"
                                src="/integris-logo.png"
                                alt="Integris Vet Verified"
                            />
                            </NavLink>
                        </div>
                        <div className="hidden md:ml-6 md:flex md:space-x-8">
                            { activeSessionId &&
                                <span className="text-white">Active Exam Session:
                                <NavLink to={`/session/${activeSessionId}`}>{examSessionInfo?.name}</NavLink></span>
                            }
                            { listItems.map((link, i) =>
                                <NavLink
                                    key={i}
                                    to={link.to}
                                    end
                                    className={classNames(
                                        link.active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'px-3 py-2 rounded-md text-sm font-medium'
                                        )}
                                    aria-current={link.active ? 'page' : undefined}
                                    >
                                        {link.displayName}
                                </NavLink>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            { activeSessionId &&
                            <button
                                type="button"
                                className="relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => clearActiveSessionId({ apiClient })}
                            >
                                <span>Exit Session</span>
                            </button>
                            }
                        </div>
                        <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                            {/* Profile dropdown */}
                            <Menu as="div" className="relative ml-3">
                                <div>
                                <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                    <span className="sr-only">Open user menu</span>
                                    <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                        <span className="font-medium text-black dark:text-black">{avatarInitials}</span>
                                    </div>
                                </Menu.Button>
                                </div>
                                <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                                >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            onClick={() => signOut()}
                                            className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                            )}
                                        >
                                            Sign Out
                                        </button>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                        <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                            <span className="font-medium text-gray-600 dark:text-gray-300">{user.intials}</span>
                        </div>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">{user.name}</div>
                      <div className="text-sm font-medium text-gray-400">{user.email}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
  )
}
