import create from 'zustand'
import _ from 'lodash'

export const useImageInfo = create((set, get) => ({
    images: {},
    activeImageId: null,
    setActiveImageId: (imageId) => set((state) => ({ ...state, activeImageId: imageId })),
    setImageList: (imageList) => {
        const list = Array.isArray(imageList)
            ? imageList.reduce((byIdHash, imgObj) => {
                byIdHash[imgObj._id] = imgObj
                return byIdHash
            }, {})
            : imageList;
        set((state) => ({ ...state, images: list }))
    },
    updateImageRoboflowDatasets: ({ id, roboflow_datasets }) => {
        set((state) => ({
            ...state,
            images: {
                ...state.images,
                [id]: {
                    ...(state.images[id] || []),
                    roboflow_datasets
                }
            }
        }))
    },
    getOrFetchImageInfo: async ({ imageId, apiClient }) => {
        let info = get().images[imageId];
        if (!info && apiClient) {
            info = await apiClient.getImageInfo(imageId)
            if (!_.isEmpty(info)) {
                set((state) => ({
                    ...state,
                    images: {
                        ...state.images,
                        [imageId]: info
                    }
                }))
            }
        }
        return info;
    },

    replaceImageInfo: (newImgInfo) => {
        set((state) => ({
            ...state,
            images: {
                ...state.images,
                [newImgInfo._id]: newImgInfo
            }
        }))
    }
}))