import create from 'zustand'
import _ from 'lodash'

export const useRoboflowInfo = create((set, get) => ({
    projects: [],
    projectDetails: {},
    getOrFetchProjects: async ({ apiClient }) => {
        let projects = get().projects
        if (!projects.length) {
            projects = await apiClient.getRoboflowProjects()
            set((state) => ({ ...state, projects: projects }))
        }
        return projects
    },
    getOrFetchProjectDetails: async ({ apiClient, roboflowProject }) => {
        let projectDetails = get().projectDetails[roboflowProject]
        if (_.isEmpty(projectDetails)) {
            projectDetails = await apiClient.getRoboflowProjectDetails(roboflowProject)
            set((state) => ({
                ...state,
                projectDetails: {
                    ...state.projectDetails,
                    roboflowProject: projectDetails,
                }
            }))
        }
        return projectDetails
    },
}))