import React from "react"
import { useEffect, useState, useRef } from "react";
import { useWaitingForImage } from "../hooks/useWaitingForImage";
import ErrorAlert from "../../../layout/components/ErrorAlert";
import { LoadingSpinner } from "../../../layout/components/LoadingSpinner";
import { CameraIcon } from '@heroicons/react/20/solid'
import { SmallIconButton } from "../../../layout/components/Button";
import { useImageInfo } from "../../../state/imageInfo";
import { useDeviceInfo } from "../../../state/deviceInfo";
import ListBox from "../../../layout/components/ListBox";
import { useApiClient } from "../../../state/apiClient";
import { useUserInfo } from "../../../state/userInfo";


const cameraTypes = {
    nncam: {
        id: 'nncam',
        name: 'NNCam',
    },
    baslerpulse5: {
        id: 'baslerpulse5',
        name: 'Basler Pulse'
    },
    raspberrypicam: {
        id: 'raspberrypicam',
        name: 'Raspberry Pi Test Cam'
    }
}

const dropdownCameraTypes = Object.keys(cameraTypes).map(type => cameraTypes[type])

export const CameraInterface = ({ setImageListLoading }) => {
    const {
        devices,
        setDeviceList,
        activeDeviceId,
        setActiveDeviceId,
        activeCameraType,
        setActiveCameraType,
    } = useDeviceInfo();

    const { waitingForImage, setWaitingForImage } = useWaitingForImage();
    const { setImageList, setActiveImageId } = useImageInfo()
    const [ onRetry, setOnRetry ] = useState(0);
    const [ showError, setShowError ] = useState(false)
    const [ dropdownDevices, setDropdownDevices ] = useState([])
    const { apiClient } = useApiClient();

    const CHECK_IMAGE_INTERVAL = 2000; // 2 seconds
    const MAX_NUMBER_REQUESTS = 20;
    const pollingTimeout = useRef();

    const activeDevice = devices[activeDeviceId] || {}

    const { getActiveSessionId } = useUserInfo()
    const sessionId = getActiveSessionId()

    const refreshImageList = async () => {
        setImageListLoading(true)
        const images = await apiClient.getImageList({ sessionId });
        setImageList(images)
        setImageListLoading(false)
    }

    const checkImageStatus = async (imageId, numRequests = 0) => {
        const imageInfo = await apiClient.getImageInfo(imageId);
        if (imageInfo?.image_status === 'uploaded') {
            window.clearTimeout(pollingTimeout.current);
            setActiveImageId(imageInfo?._id);
            refreshImageList();
            resetImageRequest()
        }
        else if (numRequests < MAX_NUMBER_REQUESTS) {
            setOnRetry(numRequests)
            pollingTimeout.current = setTimeout(() => checkImageStatus(imageId, numRequests + 1), CHECK_IMAGE_INTERVAL);
        }
        else {
            setShowError(true)
            resetImageRequest()
        }

    }

    const errorTitle = 'Whoops. That may not have worked';
    const errorMessage = `You can try another image request, or try refreshing the page
        and selecting your device again. (The requested image may still show up later
        if the system is experiencing unusual slowness).`

    const getLoadingMessage = () => {
        if (onRetry < 2) {
            return 'Grabbing the image...';
        }
        else if ( onRetry < 7 ) {
            return 'Still chugging...'
        } else if (onRetry < 12) {
            return 'Almost there...'
        } else {
            return 'Wait for it...'
        }
    }

    const resetImageRequest = () => {
        setWaitingForImage(false)
        setOnRetry(0)
    }

    const loadDeviceList = async () => {
        console.log({ apiClient })
        const deviceList = await apiClient.getDeviceList();
        setDeviceList(deviceList);
    }

    useEffect(() => {
        if(!Object.keys(devices).length) {
            loadDeviceList()
        }
    }, [])

    const sendImageRequest = async () => {
        setActiveImageId(null)
        setWaitingForImage(true);

        try {
            const requestedImageId = await apiClient.requestImage({ deviceId: activeDevice._id, cameraType: activeCameraType, sessionId });
            checkImageStatus(requestedImageId)
        } catch {
            resetImageRequest()
            setShowError(true)
        }
    }

    useEffect(() => {
        const dropdownDevices = Object.keys(devices).map((key) => ({
            id: devices[key]._id,
            name: devices[key].name,
        }));
        setDropdownDevices(dropdownDevices)
    }, [devices])

    const onDeviceChange = (selectedId) => {
        setActiveDeviceId(selectedId);
        console.log({ selectedId })
        setActiveImageId(null)
    }

    return (
        <div className="flex flex-col gap-y-2">
            { dropdownDevices.length > 0 &&
                <ListBox
                    items={dropdownDevices}
                    disabled={waitingForImage}
                    onChange={onDeviceChange}
                    selectedId={activeDeviceId}
                    label="Select Device"
                />
            }
            { activeDevice?._id &&
                <ListBox
                    items={dropdownCameraTypes}
                    disabled={waitingForImage}
                    onChange={(itemId) => setActiveCameraType(itemId)}
                    selectedId={activeCameraType}
                    label="Select Camera Type"
                />
            }
            { activeDevice?._id && activeCameraType &&
                <SmallIconButton
                    onClick={sendImageRequest}
                    icon={CameraIcon}
                    disabled={waitingForImage}
                >
                    Capture Image
                </SmallIconButton>
            }
            { waitingForImage &&
                <div className="flex">
                    <div className="self-center"><LoadingSpinner /></div>
                    <p className="font-light text-gray-400 self-center">{getLoadingMessage()}</p>
                </div>
            }
            { showError &&
                <ErrorAlert
                    title={errorTitle}
                    error={errorMessage}
                    onClose={() => setShowError(false)}
                />
            }
        </div>
    )
}