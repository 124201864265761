import axios from 'axios';
import { Auth } from 'aws-amplify';

const BASE_URL = `https://${process.env.REACT_APP_API_HOSTNAME}`

export default class ApiClient {
    constructor(loggedInUser) {
        console.log("IN API CLIENT CONSTRUCTOR")
        this.baseUrl = BASE_URL
        this.apiToken = loggedInUser?.signInUserSession.idToken.jwtToken
        this.cognitoUser = loggedInUser
        // axios.defaults.headers.common['Authorization'] = this.apiToken;
    }

    getJwtToken = async () => {
        const session = await Auth.currentSession();
        const jwt = session.getIdToken()?.getJwtToken()
        return jwt;
    }

    getCognitoUserId = () => {
        return Auth.user?.username
    }

    makeGetCall = async ({ url, params }) => {
        this.getCognitoUserId()
        console.log(this.apiToken)
        return axios.get(url, { params, headers: { Authorization: await this.getJwtToken() } })
    }

    makePostCall = async ({ url, params, body }) => {
        this.getCognitoUserId()
        let headers = {
            Authorization: await this.getJwtToken()
        }
        if (body) {
            headers['content-type'] = 'application/json'
        }
        console.log({ body })
        return axios.post(url, JSON.stringify(body), { params, headers })
    }

    getDeviceList = async () => {
        try {
            const url = `${BASE_URL}/devices`
            const rsp = await this.makeGetCall({ url })
            return rsp?.data?.devices || []
        } catch {
            return []
        }
    }

    getImageList = async ({ sessionId }) => {
        try {
            const url = `${BASE_URL}/images`;
            const params = {
                session_id: sessionId,
                auth_user: this.getCognitoUserId()
            };
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.images || []
        } catch {
            return []
        }
    }

    requestImage = async ({ deviceId, cameraType, sessionId }) => {
        try {
            const url = `${BASE_URL}/image-requests`;
            const params = {
                device_id: deviceId,
                camera_type: cameraType,
                auth_user: this.cognitoUser.username,
                session_id: sessionId
            };
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data?.image || '';
        } catch {
            throw 'Error requesting image. Please try again';
        }
    }

    getImageInfo = async (imageId) => {
        try {
            const url = `${BASE_URL}/image`;
            const params = {
                id: imageId
            };
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.image || {}
        } catch {
            return {}
        }
    }

    getRoboflowProjects = async () => {
        try {
            const url = `${BASE_URL}/roboflow/projects`
            const rsp = await this.makeGetCall({ url })
            return rsp?.data?.projects || []
        } catch {
            return []
        }
    }

    getRoboflowProjectDetails = async (roboflowProject) => {
        try {
            const url = `${BASE_URL}/roboflow/project`
            const params = {
                roboflow_project: roboflowProject
            }
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.project || {}
        } catch {
            return {}
        }
    }

    addToRoboflowDataset = async ({ projectId, imageId }) => {
        try {
            const url = `${BASE_URL}/roboflow/projects/dataset-images`

            const params = {
                roboflow_project_id: projectId,
                image_id: imageId
            }
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data || {}
        } catch {
            return {}
        }
    }

    getS3UploadUrl = async ({ fileType, contentType, sessionId }) => {
        try {
            const url = `${BASE_URL}/image-requests`;
            const params = {
                create_s3_upload_link: true,
                s3_upload_filetype: fileType,
                s3_content_type: contentType,
                auth_user: this.getCognitoUserId(),
                session_id: sessionId
            };
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data || {}
        } catch {
            throw 'Error requesting image upload link. Please try again';
        }
    }

    runRoboflowInference = async ({ modelId, imageId }) => {
        try {
            const url = `${BASE_URL}/roboflow/inference`;
            const params = {
                image_id: imageId,
                roboflow_model: modelId
            }
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data?.results || {}
        } catch {
            throw 'Could not run model. Please try again';
        }
    }

    createSession = async (sessionObj) => {
        try {
            const url = `${BASE_URL}/session`;
            const params = {
                auth_user: this.getCognitoUserId(),
            }
            const rsp = await this.makePostCall({ url, params, body: sessionObj })
            return rsp?.data?.session || {}
        } catch {
            return {}
        }
    }

    getUser = async () => {
        try {
            const url = `${BASE_URL}/user`;
            const params = {
                auth_user: this.getCognitoUserId()
            }
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.user || {}
        } catch {
            return {}
        }
    }

    setUserPreferences = async (preferences) => {
        try {
            const url = `${BASE_URL}/user/preferences`;
            const params = {
                auth_user: this.getCognitoUserId()
            }
            const rsp = await this.makePostCall({ url, body: preferences, params })
            return rsp?.data?.preferences || {}
        } catch {
            return {}
        }
    }

    getSessionInfo = async ({ sessionId }) => {
        try {
            const url = `${BASE_URL}/session`;
            const params = {
                session_id: sessionId,
                auth_user: this.getCognitoUserId()
            }
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.session || {}
        } catch {
            return {}
        }
    }

    getSessionList = async () => {
        try {
            const url = `${BASE_URL}/sessions`;
            const params = {
                auth_user: this.getCognitoUserId()
            }
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.sessions || []
        } catch {
            return []
        }
    }

}