import React, { useEffect, useState } from "react";
import { useWaitingForImage } from "../hooks/useWaitingForImage";
import { THUMBNAIL_URL } from "../../../utility/environment";
import { LoadingSpinner } from "../../../layout/components/LoadingSpinner";
import { useImageInfo } from "../../../state/imageInfo";
import { useDeviceInfo } from "../../../state/deviceInfo";


const BASE_THUMB_URL = THUMBNAIL_URL
const THUMB_FOLDER_SIZE = '200';

export const AcquiredImageCarousel = ({ loading }) => {
    const { activeImageId, images, setActiveImageId } = useImageInfo();
    const { activeDeviceId } =  useDeviceInfo();
    const { waitingForImage } = useWaitingForImage();
    const [ sortedSessionImagesForDevice, setSortedSessionImagesForDevice ] = useState([])

    const getImageLink = (image) => {
        const fileName = image?.file_name;
        return fileName ? `https://${BASE_THUMB_URL}/${THUMB_FOLDER_SIZE}/${fileName}` : '';
    }

    const activeImage = images[activeImageId]

    const setSortedImages = (sessionImages) => {
        if (activeDeviceId && sessionImages) {
            const sorted = Object.keys(sessionImages)
            .map(imgId => sessionImages[imgId])
            .filter(img => {
                return img?._device_id === activeDeviceId
            })
            .sort((imgA, imgB) => imgA?.date_completed > imgB?.date_completed)
            setSortedSessionImagesForDevice(sorted)
        } else {
            setSortedSessionImagesForDevice([])
        }
    }


    useEffect(() => {
        setSortedImages(images)
    }, [activeDeviceId, images])

    return (
        <div className="w-full flex flex-col overflow-y-auto">
            <div>
                { activeDeviceId && !loading &&
                <span className="block text-sm font-medium text-blue-gray-900">
                    Last 10 Images:
                </span>
                }
            </div>
            { loading && (
                <LoadingSpinner />
            )}
            { !loading && (
                <div className="grid grid-rows-10 grid-cols-2 gap-1">
                    {sortedSessionImagesForDevice.map((image, i) =>
                        <div key={i} className={`border-8 ${(activeImage?._id === image?._id) ? "border-sky-300" : "border-white"}`}>
                            <button onClick={() => setActiveImageId(image._id)} className="w-full h-full object-cover" disabled={waitingForImage}>
                                <img src={getImageLink(image)} className={`${waitingForImage && "opacity-50"} h-full object-cover`} />
                            </button>
                        </div>
                    )}
                </div>
            )}
            { activeDeviceId && !loading && sortedSessionImagesForDevice.length == 0 &&
                <span className="block text-sm text-gray-400">No images acquired yet.</span>
            }
        </div>
    );
}