import React, { useContext, useMemo, useState } from "react";

export const NavigationContext = React.createContext(null);

export const useNavigationContext = () => {
    const initialState = {
        menuOpen: false
    }
    const [navigationInfo, setNavigationInfo] = useState(initialState);

    const navigationContext = useMemo(() => ({
        navigationInfo,
        setNavigationInfo
    }), [navigationInfo, setNavigationInfo]);

    return navigationContext
}

export const useNavigationInfo = () => {
    return useContext(NavigationContext)
}