
export const SmallIconButton = ({ icon, children, onClick, disabled=false }) => {
    const buttonClassName = `
        ${disabled && "opacity-50"}
        inline-flex items-center rounded-md
        border border-transparent bg-primarycolor-600
        px-4 py-2 text-sm font-medium text-white shadow-sm
        hover:bg-primarycolor-700 focus:outline-none
        focus:ring-2 focus:ring-primarycolor-500
        focus:ring-offset-2"
    `
    const Icon = icon;
    return (
        <button
            type="button"
            className={buttonClassName}
            disabled={disabled}
            onClick={onClick}
        >
            {icon &&
                <Icon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            }
            {children}
        </button>
    )
}