import { useState } from "react";
import { SmallIconButton } from "../../../layout/components/Button";
import SuccessAlert from "../../../layout/components/SuccessAlert";
import { ArrowUpTrayIcon, DocumentCheckIcon, LinkIcon } from '@heroicons/react/20/solid'
import ErrorAlert from "../../../layout/components/ErrorAlert";
import moment from "moment/moment";
import { useEffect } from "react";
import { LoadingSpinner } from "../../../layout/components/LoadingSpinner";
import ListBox from "../../../layout/components/ListBox";
import { useApiClient } from "../../../state/apiClient";
import { useRoboflowInfo } from "../../../state/roboflowInfo";

const INIT_PROJECT_OPTION = { name: 'Add to Roboflow Dataset' }

export const RoboflowUploader = ({
    activeImage,
    setPerformingAction,
    updateImageInfo
}) => {
    const [uploadProject, setUploadProject] = useState(INIT_PROJECT_OPTION)
    const [isUploading, setIsUploading] = useState(false)
    const [alert, setAlert] = useState()
    const [projectDropdown, setProjectDropdown] = useState()
    const { apiClient } = useApiClient()
    const [ projects, setProjects ] = useState([])

    const { getOrFetchProjects } = useRoboflowInfo()
    useEffect(() => {
        const fetchProjects = async () => {
            const roboflowProjects = await getOrFetchProjects({ apiClient })
            setProjects(roboflowProjects)
        }
        fetchProjects()
    }, [])
    const roboflowDatasets = activeImage?.roboflow_datasets;
    console.log({ activeImage })

    useEffect(() => {
        setAlert(null)
        setUploadProject(INIT_PROJECT_OPTION)
        setIsUploading(false)
        setPerformingAction(false)
    }, [activeImage?._id])

    const inDatasets = activeImage?.roboflow_datasets && Object.keys(activeImage.roboflow_datasets) || [];

    const resetDropdown = () => {
        setUploadProject(null)
    }

    useEffect(() => {
        const dropdownProjects = projects?.length && projects.map((p) => {
            const display = inDatasets.includes(p.id) ?
            <span>{p.name} <span className="text-gray-400 italic">[already added]</span></span>
            :
            p.name

            return {
                name: display,
                id: p.id
            }
        })
        if (dropdownProjects) {
            setProjectDropdown(dropdownProjects)
        }
    }, [projects])

    const onProjectChange = (itemId) => {
        const project = projectDropdown.find(item => item.id === itemId);
        setUploadProject(project)
        setAlert(null)
    }

    const displayDate = (date) => {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    }
    const getRoboflowLink = (projectId) => {
        // TODO: fix how to handle integris namespace / projectId to be less hard-coded
        return `https://app.roboflow.com/integris/${projectId}/annotate/batch/api`
    }

    const uploadedInfo = inDatasets.map((projectId) => ({
        projectName: projects?.length && projects.find((p) => p.id === projectId).name,
        dateUploaded: displayDate(roboflowDatasets[projectId]?.date_uploaded),
        link: getRoboflowLink(projectId)
    }))

    const viewMessage = (projectId) => {
        return (<span>
            {`You should be able to see the image in the 'Uploaded via API' annotation batch for the project `}
            <a className="text-blue-600 dark:text-blue-500 hover:underline" href={getRoboflowLink(projectId)} target="_blank" rel="noopener noreferrer">here</a>
        </span>
        )
    }

    const uploadToRoboflow = async () => {
        setIsUploading(true)
        setPerformingAction(false)
        const rsp = await apiClient.addToRoboflowDataset({
            projectId: uploadProject.id,
            imageId: activeImage._id
        });

        let alert = null;
        if (rsp?.is_duplicate) {
            alert = <SuccessAlert
                title="Looks like this image is already in the dataset"
                message={viewMessage(uploadProject.id)}
                onClose={() => setAlert(null)}
            />
        } else if (rsp?.status === 'success') {
            alert = <SuccessAlert
                title="Image uploaded!"
                message={viewMessage(uploadProject.id)}
                onClose={() => setAlert(null)}
            />
        } else {
            alert = <ErrorAlert
                title="Whoops!"
                error="Looks like there may have been an error uploading to Roboflow. Try that again."
                onClose={() => setAlert(null)}
            />
        }

        // const rootNotifications = document.getElementById("root_notifications_holder")
        // rootNotifications.appendChild(alert)
        if (rsp?.roboflow_datasets) {
            updateImageInfo({
                ...activeImage,
                roboflow_datasets: rsp.roboflow_datasets
            })
        }

        setAlert(alert)
        setIsUploading(false)
        setPerformingAction(false)
        resetDropdown()

        // strip out the id
        // call upload method
            // remember to only upload to projects with 'test' in it when not prod
        // display success message (or duplicate message, or error message)
        // reload image info
    }

    return (
        <>
        {uploadedInfo.length > 0 &&
        <ul role="list" className="divide-y divide-gray-200">
            {uploadedInfo.map((uploadInfo, i) => (
                <li key={i} className="flex py-4">
                    <DocumentCheckIcon className="h-6 w-6 text-green-700" />
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                            {uploadInfo.projectName}
                            <a href={uploadInfo.link} target="_blank" rel="noreferrer" className="inline">
                                <LinkIcon className="h-4 w-4 text-gray-400 inline-block ml-1" />
                            </a>
                        </p>
                        <p className="text-sm text-gray-500">{uploadInfo.dateUploaded}</p>
                    </div>
                </li>
            ))}
        </ul>
        }
        { projects?.length > 0 &&
        <div className="flex flex-col gap-4 pt-4">
            { projectDropdown &&
                <div>
                <ListBox
                    items={projectDropdown}
                    onChange={onProjectChange}
                    selectedId={uploadProject?.id}
                    disabled={isUploading}
                    label={"Add to Roboflow Dataset:"}
                />
                </div>
            }
            { uploadProject?.id &&
                <div className="">
                <SmallIconButton onClick={uploadToRoboflow} icon={ArrowUpTrayIcon} disabled={isUploading} className="mt-4">
                    Upload to Dataset
                </SmallIconButton>
                </div>
            }
            { isUploading &&
                <LoadingSpinner />
            }
            { alert }
        </div>
        }
        </>
    )
}