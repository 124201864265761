import create from 'zustand'

export const useDeviceInfo = create((set) => ({
    activeDeviceId: null,
    activeCameraType: null,
    devices: [],
    setActiveDeviceId: (id) => set((state) => ({ ...state, activeDeviceId: id})),
    setDeviceList: (deviceList) => {
        const list = Array.isArray(deviceList)
            ? deviceList.reduce((byIdHash, deviceObj) => {
                byIdHash[deviceObj._id] = deviceObj
                return byIdHash
            }, {})
            : deviceList;
        set((state) => ({ ...state, devices: list }))
    },
    setActiveCameraType: (type) => set((state) => ({ ...state, activeCameraType: type}))
}));