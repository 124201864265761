import { MainContent } from "../../layout/MainContent"

export const HomePage = () => {
    return (
        <MainContent>
            <div className="text-center">
                <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
                    Integris Vet Verified
                </h1>
                <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
                    This is a beta administrative tool for collecting images for sperm morphology analysis.
                    Stay tuned for additional features and documentation as they are developed.
                </p>
                <p>
                    {process.env.REACT_APP_JUST_TESTING}
                </p>
            </div>
        </MainContent>
    )
}