import create from 'zustand'

export const useExamSessionInfo = create((set) => ({
    examSessionInfo: {},
    setExamSessionInfo: async (examInfo) => {
        set((state) => ({
            ...state,
            examSessionInfo: examInfo
        }))
    }
}))