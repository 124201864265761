import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ListBox({
    label,
    items,
    selectedId,
    disabled,
    onChange
}) {
    const selectedItem = selectedId && items.find(item => item.id === selectedId)
    return (
        <Listbox value={selectedId} onChange={onChange} disabled={disabled}>
            {({ open }) => (
            <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
                <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primarycolor-500 focus:outline-none focus:ring-1 focus:ring-primarycolor-500 sm:text-sm">
                    <span className="block truncate">{selectedItem?.name}&nbsp;</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>

                <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {items.map((item) => (
                        <Listbox.Option
                        key={item.id}
                        className={({ active }) =>
                            classNames(
                            active ? 'text-white bg-primarycolor-600' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                        }
                        value={item.id}
                        >
                        {({ selected, active }) => (
                            <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {item.name}
                            </span>

                            {selected ? (
                                <span
                                className={classNames(
                                    active ? 'text-white' : 'text-primarycolor-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                                >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                            </>
                        )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </>
            )}
        </Listbox>
    )
}
