import React, { useContext, useMemo, useState } from "react";

export const ActiveDeviceContext = React.createContext(null);

export const useActiveDeviceContext = () => {
    const [activeDevice, setActiveDevice] = useState({});

    const deviceContext = useMemo(() => ({
        activeDevice,
        setActiveDevice
    }), [activeDevice, setActiveDevice]);

    return deviceContext
}

export const useActiveDevice = () => {
    return useContext(ActiveDeviceContext)
}