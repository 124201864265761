import React, { useContext, useState, useEffect } from "react";
import { useApiClient } from "../../../state/apiClient";

export const RoboflowProjectsContext = React.createContext(null);

export const RoboflowProjectsContextProvider = ({ children }) => {
    const [roboflowProjects, setRoboflowProjects] = useState({});
    const { apiClient } = useApiClient()

    const fetchProjects = async () => {
        const projects = await apiClient.getRoboflowProjects();
        setRoboflowProjects(projects)
    }

    useEffect(() => {
        fetchProjects()
    }, [])

    return (
        <RoboflowProjectsContext.Provider value={roboflowProjects}>
            {children}
        </RoboflowProjectsContext.Provider>
    );
}

export const useRoboflowProjects = () => {
    return useContext(RoboflowProjectsContext)
}